@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lora&family=Poppins:wght@400;600&display=swap);
.menu-item{position:relative;-webkit-transition:background-color .2s ease,-webkit-transform .2s ease;transition:background-color .2s ease,-webkit-transform .2s ease;transition:background-color .2s ease,transform .2s ease;transition:background-color .2s ease,transform .2s ease,-webkit-transform .2s ease}.menu-item.standard .menu-item--top>*{line-height:1}.menu-item .menu-item--title,.menu-item .menu-item--prices{z-index:920;letter-spacing:.02em}.menu-item .menu-item--description{font-size:.9rem;font-weight:500;line-height:1.3}.menu-item.sortable-ghost{background-color:rgba(108,189,125,.2);opacity:.4}.menu-item.showing-ui.hovering,.menu-item.showing-ui.selected{padding-right:20px;padding-left:10px;margin-right:-20px;margin-left:-10px}.menu-item.hovering.showing-ui{background-color:rgba(207,235,223,.5)}.menu-item.selected{background-color:rgba(108,189,125,.1)}.menu-item.delete-hover{background:rgba(255,0,0,.075) !important}.menu-item .drag-handle--wrap{position:absolute;top:50%;right:0;min-width:20px;height:100%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.menu-item .drag-handle--wrap .drag-handle{font-size:18px;cursor:pointer}.menu-element--dots{border-bottom:2px dotted #000}
.menu-item{position:relative;-webkit-transition:background-color .2s ease,-webkit-transform .2s ease;transition:background-color .2s ease,-webkit-transform .2s ease;transition:background-color .2s ease,transform .2s ease;transition:background-color .2s ease,transform .2s ease,-webkit-transform .2s ease}.menu-item.standard .menu-item--top>*{line-height:1}.menu-item .menu-item--title,.menu-item .menu-item--prices{letter-spacing:.02em}.menu-item .menu-item--description{font-size:.9rem;font-weight:500;line-height:1.3}.menu-item.sortable-ghost{background-color:rgba(108,189,125,.2);opacity:.4}.menu-item.showing-ui.hovering,.menu-item.showing-ui.selected{padding-right:20px;padding-left:10px;margin-right:-20px;margin-left:-10px}.menu-item.hovering.showing-ui{background-color:rgba(207,235,223,.5)}.menu-item.selected{background-color:rgba(108,189,125,.1)}.menu-item.delete-hover{background:rgba(255,0,0,.075) !important}.menu-element--dots{border-bottom:2px dotted #000}.drag-handle--wrap{position:absolute;top:50%;right:0;min-width:20px;height:100%;-webkit-transform:translateY(-50%);transform:translateY(-50%)}.drag-handle--wrap .drag-handle{font-size:18px;cursor:pointer}
.callout-item .menu-item--prices{font-weight:normal !important;text-align:left !important}
.menu-item.condensed{position:relative}.menu-item.condensed .item-price{font-weight:normal}
.menu-section--title[data-v-db782a38]{font-size:60px;line-height:.7}.menu-section.callout[data-v-db782a38]{max-width:300px;margin-right:auto;margin-left:auto;border:4px solid #940152;border-radius:6px}.menu-section.callout .menu-section--items[data-v-db782a38]{margin:1em 0}.menu-section.callout .callout-title--icon[data-v-db782a38]{height:48px;margin:-3px 12px -3px 0}
.menu-section{position:relative}.menu-section.subsection{margin-top:50px}.menu-section--title{margin-bottom:.1em;font-size:60px;line-height:.7}.menu-section--subtitle{font-size:1.15rem;font-weight:500}.menu-section--subtitle i{font-size:.8rem}.menu-entity--buttons-wrap.Section-buttons{z-index:929}.menu-entity--buttons-wrap.Section-buttons.top{top:0;padding-bottom:30px;-webkit-transform:translateY(-100%);transform:translateY(-100%)}.menu-entity--buttons-wrap.Section-buttons.bottom{top:auto;bottom:0;padding-top:30px;-webkit-transform:translateY(100%);transform:translateY(100%)}

/*# sourceMappingURL=chunk-1bbdad49.36dc22ca.css.map*/