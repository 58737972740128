





















































































































































@import '@design';

.menu-section {
  position: relative;
  &.subsection {
    margin-top: 50px;
  }
}

.menu-section--title {
  margin-bottom: 0.1em;
  font-size: 60px;
  line-height: 0.7;
  // @media (max-width:600px) {
  //   position:sticky;
  //   top:0;
  //   background-color:white;
  // }
}

.menu-section--subtitle {
  font-size: 1.15rem;
  font-weight: 500;
  span {
  }
  i {
    // font-weight:300;
    font-size: 0.8rem;
  }
}

.menu-entity--buttons-wrap.Section-buttons {
  z-index: 929;

  $distance: 30px;

  &.top {
    top: 0;
    padding-bottom: $distance;
    transform: translateY(-100%);
  }
  &.bottom {
    top: auto;
    bottom: 0;
    padding-top: $distance;
    transform: translateY(100%);
  }
}
